import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Main from './pages/main';
import FAQ from './pages/faq';
import PolitykaPrywatnosci from './pages/politykaprywatnosci';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main/>}></Route>
        <Route path="/pomoc" element={<FAQ/>}></Route>
        <Route path="/polityka-prywatnosci" element={<PolitykaPrywatnosci/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
