import Navbar from '../components/navbar';
import Footer from '../components/footer';

function PolitykaPrywatnosci() {
    return (
        <>
            <Navbar></Navbar>
            <div className='gradient'>
                <main>
                    <div className='card polityka'>
                        <h1>
                            Polityka prywatności
                        </h1>
                        <p>Ta strona opisuje sposób wykorzystania i zbierania danych przez aplikację Termin Prawka. Aplikacja została stworzona przez SobGOG Martin Gogołowicz. Aplikacja Termin Prawka zbiera i zapisuje następujące dane:</p>
                        <ul>
                            <li>Pobieramy z urządzenia token do wysyłania powiadomień. Jest on unikatowy dla każdego urządzenia. Następnie łączymy ten token z kontem i wysyłamy za pomocą niego powiadomienia na urządzenie mobilne.</li>
                            <li>Przy zakładaniu konta zbierany jest email, wykorzystujemy go jedynie w celach autoryzacji, nie wysyłamy na niego żadnych powiadomień.</li>
                            <li>Przedłużając darmowe powiadomienia możemy wyświetlić reklamę, wtedy będziemy przetwarzać takie dane jak Twój token urządzenia w celach reklamowych.</li>
                        </ul>
                        <p>Zbierając powyższe dane, aplikacja wykorzystuje usługi Firebase zarządzane przez firmę Google: <a href='https://firebase.google.com/support/privacy?hl=pl' target='_blank' rel="noreferrer">link do polityki prywatności firmy Google.</a> Dane urządzenia oraz dane dotyczące aktywności w aplkacji są przechowywane na stałe.</p>
                    </div>
                    <div className='card'>
                        <h1>Kontakt</h1>
                        <p>Wszelkie pytania prosimy kierować na podany email - support@pjazdy.pl</p>
                    </div>
                    <Footer></Footer>
                </main>
            </div>
        </>
    );
}

export default PolitykaPrywatnosci;
