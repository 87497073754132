import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };


    return (
        <>
            <nav>
                <img src="img/logo.png" alt="Logo Termin Prawka"></img>
                <a href='/'>
                    Strona główna
                </a>
                <a href='/pomoc'>
                    Pomoc (FAQ)
                </a>
                <a href='/polityka-prywatnosci'>
                    Polityka prywatności
                </a>

                <button onClick={toggleMenu} id='mobileNav'>
                    <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
                </button>
            </nav>

            <div className={`mobilemenu ${isMenuOpen ? 'opened' : 'closed'}`}>
                <a href="/">
                    <p>Strona Główna</p>
                </a>
                <a href="/pomoc">
                    <p>Pomoc (FAQ)</p>
                </a>
                <a href="/polityka-prywatnosci">
                    <p>Polityka prywatności</p>
                </a>
                <div id='navCredits'>
                    <p>&copy; SobGOG 2024</p>
                    Martin Gogołowicz, Paweł Mazurowski
                </div>
            </div>
        </>

    );
}

export default Navbar;
