import Navbar from '../components/navbar';
import Footer from '../components/footer';

function FAQ() {
    return (
        <>
            <Navbar></Navbar>
            <div className='gradient'>
                <main>
                    <div className='card'>
                        <h1>Pytania i odpowiedzi</h1>
                    </div>

                    <div className='card faqCard'>
                        <h2>Dlaczego pojawiają się wolne terminy?</h2>
                        <hr></hr>
                        <p>Wolne terminy pojawiają się, gdy jakaś osoba zrezygnuję ze swojego egzaminu, wtedy Ty możesz zapisać się na jej miejsce.</p>
                    </div>

                    <div className='card faqCard'>
                        <h2>Czy muszę podawać moje dane osobiste lub PKK?</h2>
                        <hr></hr>
                        <p>Nie, nasza aplikacja nie wymaga żadnych Twoich danych związanych z prawem jazdy. Potrzebujesz tylko email do założenia darmowego konta :)</p>
                    </div>

                    <div className='card faqCard'>
                        <h2>W jaki sposób wysyłane są powiadomienia?</h2>
                        <hr></hr>
                        <p>Powiadomienia są wysyłane na urządzenie mobilne w formie powiadomienia aplikacji, pamiętaj włączyć powiadomienia!</p>
                    </div>

                    <div className='card faqCard'>
                        <h2>Gdzie jest haczyk?</h2>
                        <hr></hr>
                        <p>Nie ma haczyka, utrzymujemy naszą infrastrukturę z Twojego obejrzenia reklamy raz na 3 dni, zajmie Ci to maksymalnie 30 sekund co 3 dni. Pozwoli Ci to otrzymywać powiadomienia najszybciej, każda inna aplikacja posiada albo płatną wersję albo dłuższy czas odświeżania terminów.</p>
                    </div>

                    <Footer></Footer>
                </main>
            </div>
        </>
    );
}

export default FAQ;
