function Footer() {
    return (
        <footer>
            <img src="img/logo.png" alt="Logo Termin Prawka"></img>
            <p>© Copyright SobGOG | Martin Gogołowicz</p>
            <p>Wykonanie strony: Paweł Mazurowski</p>
        </footer>
    );
}

export default Footer;
